const { useState, useContext, useEffect } = wp.element;
import Carousel from 'react-bootstrap/Carousel';
import ReactHtmlParser from 'react-html-parser';


function ControlledCarousel({eventsList}) {

  const [formattedSlider, setSlider] = useState(null);
  console.log(eventsList);


  useEffect(() => {
    let output = [];
    let smallArr = [];
    let numOfSlides = 4;
    for(let i = 0; i < eventsList.length; i++){
      smallArr.push(eventsList[i]);
      if(smallArr.length >= numOfSlides || i === eventsList.length - 1){
        output.push(smallArr);
        smallArr = [];
      }
    }
    setSlider(output);
  },[eventsList])

  function dateFormatter(date) {
    let formattedDate;
    if(date){
      let dateArr = date.split('');
      dateArr.splice(4,0,'-');
      dateArr.splice(7,0,'-');
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
      formattedDate = formattedDate.toDateString().split(' ').slice(1).join(' ');
    }
    return formattedDate;
  }


  return (
    <>
    {eventsList.length > 0
      ? <div id="carouselExampleIndicators" class="carousel slide" data-ride="">
        <div class="carousel-inner">
        {formattedSlider &&
          formattedSlider.map((item, index) => {
            // console.log(index);
            return (
              <div className={index === 0 ? "carousel-item active" : `carousel-item item-${index}`}>
              {item.map((innerItem) => {
                return (
                  <a href={innerItem.link}>
                    <div className="col-md-3 event-item">
                      <img className="event-img" src={innerItem.acfImageUrl} alt="Card image" />
                      <div className="overlay">
                          <h5 className="event-title">{(innerItem.yoast_head_json.og_title)}</h5>
                          <p className="event-dates">{dateFormatter(innerItem.acf.start_date)} - {dateFormatter(innerItem.acf.end_date)}</p>
                      </div>
                    </div>
                  </a>
                )
              })}
              </div>
            )
          })
        }
        </div>
        <div>
          <ol class="carousel-indicators">
            {formattedSlider &&
              formattedSlider.map((item, index) => {
                // console.log(index);
                return (
                  <li data-target="#carouselExampleIndicators" data-slide-to={`${index}`} class={index === 0 ? "active" : ""}></li>
                )
              })}
          </ol>
        </div>
      </div>
      : <div className="row">There are no upcoming events in this category</div>
    }
    </>
  );
}

export default ControlledCarousel;
