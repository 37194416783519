const {useState, useEffect} = wp.element;
import ListItem from './ListItem';
import CalendarView from './CalendarView';
import CarouselView from './CarouselView';
import EventList from './EventList';
import EventDetails from './EventDetails';
import carouselIcon from './carousel-icon.png';
import carouselIconGold from './carousel-view-gold.png';
import listIcon from './list-icon.png';
import listIconGold from './list-view-gold.png';
let arrayForHoldingEvents = [];

const UpcomingEvents = ({ eventItems, content, eventCategory }) => {
  const [view, setView] = useState('carousel');
  const [categories, setCategories] = useState(eventItems);
  const [selected, setSelected] = useState(eventCategory);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [eventsToShow, setEventsToShow] = useState([]);
  const [next, setNext] = useState(4);
  const [page, setPage] = useState(1);
  const eventsPerPage = 4;
  const [totalPages, setTotalPages] = useState(Math.ceil(eventItems.length / 4));

  // console.log(content);
// console.log(eventItems);

  const loopWithSlice = (start, end) => {
    const slicedEvents = categories.slice(start, end);
    arrayForHoldingEvents = [...arrayForHoldingEvents, ...slicedEvents];
    // console.log(eventItems);
    // console.log(categories);
    // console.log(arrayForHoldingEvents);
    setEventsToShow(arrayForHoldingEvents);
  };



  useEffect(() => {
    loopWithSlice(0, eventsPerPage);
    // console.log('inside use effect');
  }, [categories]);


  const handleShowMoreEvents = () => {
    setBtnLoading(true);
    setPage(page + 1);
    loopWithSlice(next, next + eventsPerPage);
    setNext(next + eventsPerPage);
    setBtnLoading(false)
  };


  function categorySelect(e) {
    // console.log(e.target.getAttribute('name'));
    let name = e.target.getAttribute('name');
    // setEventsToShow([]);
    // console.log(eventsToShow);
    arrayForHoldingEvents = [];
    let filteredResult = [];
    if(name === content.allSlug){
      // console.log('all items');
      setCategories(eventItems);
      // setSelected(name)
      setTotalPages(Math.ceil(eventItems.length / 4));
    } else {
      eventItems.forEach((item, i) => {
        let categoryName = item.category_names;
        for(let i = 0; i < categoryName.length; i++){
          if(categoryName[i] === name){
            filteredResult.push(item)
          }
        }
      });
      // console.log(eventItems);

      setCategories(filteredResult);
      // console.log(arrayForHoldingEvents);
      // console.log(eventsToShow);
      // console.log(categories);
      setTotalPages(Math.ceil(filteredResult.length / 4));
      setPage(1);
    }
    setSelected(name);
    setNext(4);
    // console.log(eventItems);
    // console.log(filteredResult);
    // return filteredResult;
  }

  // console.log(selected);
  // console.log(page);
  // console.log(next);


  return (
    <div className="upcoming-events-section">
        <div class="row header justify-content-center justify-content-md-start">
          <div>
            <h2>{content.upcoming} |</h2>
          </div>
          <div class="upcoming-events-categories">
            <ul class="nav">
              <li class="nav-item">
                <span className={selected === content.communitySlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.communitySlug}>{content.community}</span>
              </li>
              <li class="nav-item">
                <span className={selected === content.griefSlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.griefSlug}>{content.grief}</span>
              </li>
              <li class="nav-item">
                <span className={selected === content.museumSlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.museumSlug}>{content.museum}</span>
              </li>
              <li class="nav-item">
                <span className={selected === content.allSlug ? "nav-link active" : "nav-link"} name={content.allSlug} onClick={(e) => {
                   categorySelect(e);
                }}>{content.all}</span>
              </li>
            </ul>
          </div>
          <div className="views">{content.view}:
            <span name="list" onClick={() => setView('list')} className="view-item">
              <img src={view === 'list' ? listIconGold : listIcon} />
            </span>
            <span className="view-item">
              |
            </span>
            <span name="carousel" onClick={() => setView('carousel')} className="view-item">
              <img src={view === 'carousel' ? carouselIconGold : carouselIcon} />
            </span>
          </div>
        </div>
        {categories
          ? <div className="row">
            {view === 'list'
              ? <div className="col-12">
                  <ListItem eventsList={eventsToShow} content={content} />
                </div>
              : <div className="col-12">
                  <EventDetails eventsList={eventsToShow} content={content} />
                </div>
            }
            {totalPages !== page && totalPages > page && <div class="load-btn"><button className="btn btn-load-more" onClick={handleShowMoreEvents}>{btnLoading ? `${loadEvents}...` : content.loadMore}</button></div>}
          </div>
          : <div className="row">
            {loading === true ? <p>Loading Events...</p> : <p>{content.noEvents}</p>}
          </div>
        }

    </div>
  )
}

export default UpcomingEvents;
