import CarouselItem from './CarouselItem';

const CarouselView = ({eventItems}) => {
  // console.log('here');

  return (
      <div className="container">
        <CarouselItem eventsList={eventItems}/>
      </div>
  );
};
export default CarouselView;
