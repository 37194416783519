const { useContext, useState, useEffect } = wp.element;
import PastEventDetail from './PastEventDetail';
let arrayForHoldingEvents = [];

const PastEvents = ({eventItems, content, eventCategory}) => {
  const [eventsToShow, setEventsToShow] = useState([]);
  const [next, setNext] = useState(4);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const eventsPerPage = 4;
  const [totalPages, setTotalPages] = useState(Math.ceil(eventItems.length / 4));
  const [categories, setCategories] = useState(eventItems);
  const [selected, setSelected] = useState(eventCategory);

  // console.log(content);
  // console.log(eventItems);
  // console.log(categories);


  function categorySelect(e) {
    // console.log(e.target.getAttribute('name'));
    let name = e.target.getAttribute('name');
    // setEventsToShow([]);
    // console.log(eventsToShow);
    arrayForHoldingEvents = [];
    let filteredResult = [];
    if(name === content.allSlug){
      // console.log('all items');
      setCategories(eventItems);
      // setSelected(name)
      setTotalPages(Math.ceil(eventItems.length / 4));
    } else {
      eventItems.forEach((item, i) => {
        let categoryName = item.category_names;
        for(let i = 0; i < categoryName.length; i++){
          if(categoryName[i] === name){
            filteredResult.push(item)
          }
        }
      });
      // console.log(eventItems);

      setCategories(filteredResult);
      setTotalPages(Math.ceil(filteredResult.length / 4));
      setPage(1);
    }
    setSelected(name);
    setNext(4);
  }


  const loopWithSlice = (start, end) => {
    const slicedEvents = categories.slice(start, end);
    arrayForHoldingEvents = [...arrayForHoldingEvents, ...slicedEvents];
    // console.log(arrayForHoldingEvents);
    setEventsToShow(arrayForHoldingEvents);
  };

  useEffect(() => {
    loopWithSlice(0, eventsPerPage);
  }, [categories]);

  const handleShowMoreEvents = () => {
    setLoading(true);
    setPage(page + 1);
    loopWithSlice(next, next + eventsPerPage);
    setNext(next + eventsPerPage);
    setLoading(false)
  };


  function dateFormatter(date) {
    let formattedDate;
    if(date){
      let dateArr = date.split('');
      dateArr.splice(4,0,'-');
      dateArr.splice(7,0,'-');
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
      formattedDate = formattedDate.toDateString().split(' ').slice(1).join(' ');
    }
    return formattedDate;
  }


  return (
    <div className="past-events-section">
      <div class="row header justify-content-center justify-content-md-start">
        <div>
          <h2>{content.past} | </h2>
        </div>
        <div class="past-events-categories">
          <ul class="nav">
            <li class="nav-item">
              <span className={selected === content.communitySlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.communitySlug}>{content.community}</span>
            </li>
            <li class="nav-item">
              <span className={selected === content.griefSlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.griefSlug}>{content.grief}</span>
            </li>
            <li class="nav-item">
              <span className={selected === content.museumSlug ? "nav-link active" : "nav-link"} onClick={(e) => categorySelect(e)} name={content.museumSlug}>{content.museum}</span>
            </li>
            <li class="nav-item">
              <span className={selected === content.allSlug ? "nav-link active" : "nav-link"} name={content.allSlug} onClick={(e) => {
                 categorySelect(e);
              }}>{content.all}</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <PastEventDetail eventsList={eventsToShow} content={content} />
        {totalPages !== page && totalPages > page && <div class="load-btn"><button className="btn btn-load-more" onClick={handleShowMoreEvents}>{loading ? `${content.loadEvents}...` : content.loadMore}</button></div>}
      </div>
    </div>
  )
}

export default PastEvents;
