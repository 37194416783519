function PastEventDetail({eventsList, content}) {

  function dateFormatter(date) {
    const options = { year: 'numeric', month: 'long' };

    // console.log(date.toLocaleDateString('en-us', options));
    // console.log(content.code);
    let formattedDate;
    let pastDate;
    if(date){
      let dateArr = date.split('');
      dateArr.splice(4,0,'-');
      dateArr.splice(7,0,'-');
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
      pastDate = formattedDate.toLocaleDateString(content.code, options);
      // console.log(formattedDate.toLocaleDateString(content.code, options));
      // formattedDate = formattedDate.toDateString().split(' ').slice(1).join(' ');
      // let month = formattedDate.split(' ').splice(0,1).join(' ');
      // let year = formattedDate.split(' ').splice(2).join(' ');
      // pastDate = month.concat(" ", year);
      pastDate = pastDate.charAt(0).toUpperCase() + pastDate.slice(1)
      // console.log(pastDate);
    }
    return pastDate;
  }

  return (
    <div className="row">
      {eventsList.length > 0
        ? eventsList.map((eventItem, index) => {
          return (
            <div className="col-md-3 col-sm-12">
              <a href={eventItem.link}>
                <div className="event-item bg-dark text-white">
                  <img className="event-img" src={eventItem.acfImageUrl} alt="Card image" />
                  {/*<div className="overlay">
                      <h5 className="event-title">{eventItem.title.rendered}</h5>
                      <p className="event-dates">{eventItem.acf.start_date} - {eventItem.acf.end_date}</p>
                  </div>*/}
                </div>
                <div className="past-event-item-title">
                  <h4>{eventItem.yoast_head_json.og_title}</h4>
                  <p>{dateFormatter(eventItem.acf.start_date)}</p>
                </div>
              </a>
            </div>
          )
        })
        : <h3 style={{"margin": "40px auto", "text-align": "center"}}>{content.noPast}</h3>
      }

    </div>
  )
}

export default PastEventDetail;
