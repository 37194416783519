const { createContext, useState, useEffect } = wp.element;
import UpcomingEvents from './views/UpcomingEvents';
import PastEvents from './views/PastEvents';
import { Spinner, Modal, Button } from "react-bootstrap";
import useFetch from './hooks/useFetch';
import { css } from "@emotion/react";
import DotLoader from "react-spinners/DotLoader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const lang = {
  english: {
    code: 'en-us',
    upcoming: 'Upcoming Events',
    past:  'Past Events',
    related: 'Related Events',
    community: 'Community',
    grief: 'Grief',
    museum: 'Museum',
    all: 'All',
    communitySlug: 'community-cv',
    museumSlug: 'museum-cv',
    griefSlug: 'grief-cv',
    allSlug: 'all-events',
    view: 'View',
    loadMore: 'Load More',
    loadEvents: 'Loading Events',
    noEvents: 'There are no upcoming events scheduled',
    noUpcoming: 'There are no upcoming events in this category',
    noPast: 'There are no past events in this category'
  },
  spanish: {
    code: 'es-es',
    upcoming: 'Próximos Eventos',
    past: 'Eventos Pasados',
    view: 'Ver',
    related: 'Eventos Relacionados',
    community: 'Comunidad',
    grief: 'Duelo',
    museum: 'Museo',
    all: 'Todos',
    communitySlug: 'community-cv-sp',
    museumSlug: 'museum-cv-sp',
    griefSlug: 'grief-cv-sp',
    allSlug: 'all-events-es	',
    loadMore: 'Cargar Más',
    loadEvents: 'Cargando Eventos',
    noEvents: 'No hay eventos calendarizados en el futuro',
    noUpcoming: 'No hay eventos en esta categoría en el futuro',
    noPast: 'No hay eventos en esta categoría'
  },
  chinese: {
    code: 'zh-hant',
    upcoming: '近期活動',
    past: '活動回顧',
    view: '瀏覽模式',
    related: '相關活動',
    community: '社區活動',
    grief: '悲傷輔導',
    museum: '博物館',
    all: '全部活動',
    communitySlug: 'community-zh-hant',
    museumSlug: 'museum-zh-hant',
    griefSlug: 'grief-zh-hant',
    allSlug: 'all-events-zh-hant',
    loadMore: '加載更多',
    loadEvents: '活動加載中',
    noEvents: '近期沒有安排活動',
    noUpcoming: '此類別中沒有近期活動',
    noPast: '此類別中沒有活動回顧'
  }
}

const App = () => {
  const [upcomingList, setUpcomingList] = useState([]);
  const [pastList, setPastList] = useState([]);
  const [noEvents, setNoEvents] = useState(false);
  const [eventsToDisplay, setEventsToDisplay] = useState([])
  // const [loading, setLoading] = useState(true);
  const pageLanguage =   $("html").attr("lang");
  let language, content;
  if(pageLanguage === 'zh-hant'){
    language = 'zh-hant';
    content = lang.chinese;
  } else if (pageLanguage === 'es-ES') {
    language = 'es';
    content = lang.spanish;
  } else {
    language = '';
    content = lang.english;
  }
  const {loading, data} = useFetch(`https://forestlawn.com/${language}/wp-json/wp/v2/fl_events?per_page=100`);
  // const {loading, data} = useFetch(`http://forestlawn.web01.forestlawn.intra/${language}/wp-json/wp/v2/fl_events?per_page=100`);

  // console.log(data);

  function dateFormatter(date) {
    let formattedDate;
    if(date){
      let dateArr = date.split('');
      if(dateArr.length > 8){
        dateArr.splice(4,1);
        dateArr.splice(6,1);
      } else {
        dateArr.splice(4,0,'-');
        dateArr.splice(7,0,'-');
      }
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
    }
    return formattedDate;
  }


  useEffect(() => {
    if(data.length > 1){
      let currentDate = new Date();
      let eventsList = [];
      data.sort((a,b) => b.acf.start_date - a.acf.start_date);
      let sortedEventsByLanguage = data.filter(function(evt) {
        if(evt.category_names !== null){
            evt.category_names.map((filtered_event) => {
              if(filtered_event === content.griefSlug || filtered_event === content.museumSlug || filtered_event === content.communitySlug){
                eventsList.push(evt);
                return;
              }
            })
        }
      });
      setEventsToDisplay(eventsList);
    
      let upcoming = eventsList.filter(item => dateFormatter(item.acf.end_date) > currentDate);
      let past = eventsList.filter(item => dateFormatter(item.acf.end_date) < currentDate);

      upcoming.sort((a,b) => a.acf.start_date - b.acf.start_date);
      past.sort((a,b) => b.acf.start_date - a.acf.start_date);
      setUpcomingList(upcomingList.concat(upcoming));
      setPastList(pastList.concat(past));
    }
  },[data])


  return (
      <>
         {loading &&
           <h3 className="text-center my-15">{content.loadEvents}...</h3>
         }
         <DotLoader color={'#507336'} loading={loading} css={override} size={100} />
         <div className="container-fluid">
            {upcomingList.length > 0
              ? <UpcomingEvents eventItems={upcomingList} content={content}/>
              : <div class="row header justify-content-center" style={{"margin":"30px auto"}}>
                {!loading &&
                  <h3>{content.noEvents}</h3>
                }
                </div>
            }
            {pastList.length > 0 &&
              <PastEvents eventItems={pastList} content={content} eventCategory={/grief-events/.test(window.location.href) ? content.griefSlug : content.allSlug} />
            }
          </div>
      </>
  );
};

export default App;
