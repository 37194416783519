const CalendarView = () => {
  console.log('im in calendar view');
  return (
    <div>
    <div>
    <div className="events-period">
      <h2>Upcoming Events | </h2>
    </div>
    <div className="categories">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item" onClick={() => setView(CarouselView)}>Community | </li>
        <li className="nav-item" onClick={() => setView(ListView)}>Grief | </li>
        <li className="nav-item" onClick={() => setView(CalendarView)}>Museum | </li>
        <li className="nav-item" onClick={() => setView(CalendarView)}>All</li>
      </ul>
    </div>
    <div className="views">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item" onClick={() => setView(CarouselView)}>Carousel | </li>
        <li className="nav-item" onClick={() => setView(ListView)}>List | </li>
      </ul>
    </div>
  </div>
    </div>
  );
};
export default CalendarView;
