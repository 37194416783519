const { useState, useContext, useEffect } = wp.element;
import ReactHtmlParser from 'react-html-parser';


function EventDetails({eventsList, content}) {
  // console.log(eventsList);


  function dateFormatter(date) {
    let formattedDate;
    if(date){
      let dateArr = date.split('');
      dateArr.splice(4,0,'-');
      dateArr.splice(7,0,'-');
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
      formattedDate = formattedDate.toDateString().split(' ').slice(1).join(' ');
    }
    return formattedDate;
  }


  return (
    <div>
    {eventsList &&
      eventsList.map((item, index) => {
        return (
          <a href={item.link}>
            <div className="col-md-3 event-item">
              <img className="event-img" src={item.acfImageUrl} alt="Card image" />
              <div className="overlay">
                  <h5 className="event-title">{(item.yoast_head_json.og_title)}</h5>
                  <p className="event-excerpt">{ReactHtmlParser(item.acf.excerpt_text)}</p>
              </div>
            </div>
          </a>
        )
      })
    }
    {eventsList.length === 0 && <div><h3>{content.noUpcoming}</h3></div>}
    </div>
  );
}

export default EventDetails;
