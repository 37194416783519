const {useState, useEffect} = wp.element
import EventDetails from './EventDetails';
let arrayForHoldingEvents = [];



const EventsList = ({eventItems}) => {
  const [eventsToShow, setEventsToShow] = useState([]);
  const [next, setNext] = useState(4);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const eventsPerPage = 4;
  const totalPages = Math.ceil(eventItems.length / 4);



  // console.log(eventItems);
  console.log(totalPages);
  console.log(page);


  const loopWithSlice = (start, end) => {
    const slicedEvents = eventItems.slice(start, end);
    arrayForHoldingEvents = [...arrayForHoldingEvents, ...slicedEvents];
    console.log(eventItems);
    console.log(arrayForHoldingEvents);
    setEventsToShow(arrayForHoldingEvents);
  };



  useEffect(() => {
    loopWithSlice(0, eventsPerPage);
    console.log('inside use effect');
  }, [eventItems]);


  const handleShowMoreEvents = () => {
    setLoading(true);
    setPage(page + 1);
    loopWithSlice(next, next + eventsPerPage);
    setNext(next + eventsPerPage);
    setLoading(false)
  };


  // console.log(eventItems);

  return (
    <div>
      <EventDetails eventsList={eventsToShow} />
      {totalPages !== page && totalPages > page && <div class="load-btn"><button className="btn btn-load-more" onClick={handleShowMoreEvents}>{loading ? 'Loading...' : 'Load More'}</button></div>}
    </div>
  )
}

export default EventsList;
