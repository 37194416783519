const { useContext } = wp.element;
import dateFormat from "dateformat";
import ReactHtmlParser from 'react-html-parser';



const ListItem = ({eventsList, content}) => {
  // console.log(eventsList.length);

  function dateFormatter(date) {
    let formattedDate;
    if(date){
      let dateArr = date.split('');
      dateArr.splice(4,0,'-');
      dateArr.splice(7,0,'-');
      let dateString = dateArr.join('');
      formattedDate = new Date(dateString);
      formattedDate.setDate(formattedDate.getDate() + 1);
      formattedDate = formattedDate.toDateString().split(' ').slice(1).join(' ');
    }
    return formattedDate;
  }

  return (
    <div className="">
      <ul className="list">
      {eventsList.length > 0 &&
        eventsList.map((eventItem, index) => {

        let startDate = dateFormatter(eventItem.acf.start_date)
        let endDate = dateFormatter(eventItem.acf.end_date);
        return (
          <a href={eventItem.link}>
            <li className="list-item">
              <p><span className="list-event-title">{eventItem.yoast_head_json.og_title}</span><br />
                {ReactHtmlParser(eventItem.acf.excerpt_text)}
              </p>
            </li>
          </a>
        )
      })}
      </ul>
      {eventsList.length === 0 && <div><h3>{content.noUpcoming}</h3></div>}
    </div>
  )
}

export default ListItem;
